import React from 'react'

const BlogSidebar = () => {
  
    return (
        <div className="widget-area">
            <div className="widget widget_categories">
            <h3 className="widget-title">menu</h3>

            <ul>
            <li><a href="https://bgreen.co.jp">株式会社Bgreen 公式サイト</a></li>
		<li><a href="https://bgreen.jp/lp/">格安LP作成サービス</a></li>
		<li><a href="https://sakuweb.site/">格安ウェブサイト制作サービス</a></li>
		<li><a href="https://landingpage-stock.com/">ランディングページ・デザイン集</a></li>
            </ul>
        </div>
        </div>
    )
}

export default BlogSidebar